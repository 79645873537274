<template>
  <v-container>
    <a href="https://www.sapporo.coop/content/?id=2478">
      <v-img
        :src="require('@/assets/todok-app-link-banner.png')"
        alt="お店でアプリの組合員証を提示するとポイントが貯まる!!詳しくはこちら"
      />
    </a>

    <v-row justify="center" class="my-2"
      ><v-btn
        height="52"
        width="100%"
        color="memberid-secondary"
        class="rounded-pill memberid-white--text font-weight-bold subtitle-1 for-ga-app-detail-btn"
        href="https://www.sapporo.coop/content/?id=2478"
      >
        アプリについて詳しくはこちら
      </v-btn></v-row
    >
    <v-row justify="center" class="my-5 memberid-secondary--text font-weight-bold">＼アプリで今すぐ使おう！（登録無料）／</v-row>
    <v-row class="justify-center" align="center">
      <div>
        <a href="https://apps.apple.com/jp/app/%E3%83%88%E3%83%89%E3%83%83%E3%82%AF%E3%82%A2%E3%83%97%E3%83%AA/id1473905794">
          <v-img :src="require('@/assets/badge-appstore.svg')" max-width="140" />
        </a>
      </div>
      <div>
        <a href="https://play.google.com/store/apps/details?id=coop.sapporo.todock">
          <v-img :src="require('@/assets/badge-googleplay.png')" max-width="140" />
        </a>
      </div>
    </v-row>
    <v-row justify="start" class="mx-1 mt-1 pt-3 text-caption memberid-black-400--text">※システムへの反映に最大10分かかるため、アプリのログインは今から時間をおいてお試しください。</v-row>
    <v-row justify="start" class="mx-1 mt-1 pt-3 text-caption memberid-black-400--text">※また、システムメンテナンス中のため、0〜7時に受付完了した分は、7時以降に順次反映します。</v-row>
    <v-row justify="start" class="mx-1 mt-1 pt-3 text-caption memberid-black-400--text">※ちょこっとカードをご利用いただく際、初回のみ店舗サービスカウンターにてチャージしてからご利用ください。</v-row>
    <v-row justify="start" class="mx-1 mt-1 pt-3 text-caption memberid-black-400--text">※たまったポイントはちょこっとカードに自動的にチャージされます。不要な方は店舗カウンターにお申し出ください。</v-row>
  </v-container>
</template>
<script>
export default {
  name: 'AppRecommendComponent',
}
</script>
