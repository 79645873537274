<template>
  <div class="pc-wrap memberid-signup-main mb-10">
    <v-container class="mt-1">
      <h3 class="memberid-black-600--text title font-weight-bold">配送先情報の入力</h3>
      <p class="mt-2">宅配申し込みに必要な情報の入力をお願いします。</p>
      <v-form v-model="deliveryValid" ref="deliveryForm">
        <radio-button
          :inputTitle="'宅配配達住所'"
          :requiredFlag="true"
          :placementTextList="['組合員情報と同じ住所に配達する', '別の住所に配達する']"
          :value.sync="delivery.selectedAddress"
          :errorMessages="selectedAddressErrors()"
        />
        <v-sheet v-if="delivery.selectedAddress === 1" class="rounded-lg memberid-black-100 pa-4 mt-8">
          <zip-code-component
            :value.sync="delivery.zipCode"
            :addressList.sync="addressList"
            v-on:custom="
              clearAddress()
              $v.delivery.address1.$touch()
            "
          />
          <select-box
            :inputTitle="address1.title"
            :requiredFlag="address1.required"
            :placeholder="address1.placeholder"
            :value.sync="delivery.address1"
            :messages="address1.messages"
            :items="addressList"
            :isDisabled="addressList && addressList.length === 0"
            :errorMessages="validAddress1()"
          />
          <single-valid-text-field
            :inputTitle="address2.title"
            :requiredFlag="address2.required"
            :placeholder="address2.placeholder"
            :value.sync="delivery.address2"
            :errorMessages="validAddress2()"
            :caption="'※全角'"
            v-on:blur="$v.delivery.address2.$touch()"
          />
          <single-valid-text-field
            :inputTitle="address3.title"
            :requiredFlag="address3.required"
            :placeholder="address3.placeholder"
            :value.sync="delivery.address3"
            :errorMessages="validAddress3()"
            v-on:blur="$v.delivery.address3.$touch()"
          />
        </v-sheet>

        <br />
        <radio-button
          :inputTitle="'日中連絡の取れる電話番号'"
          :requiredFlag="true"
          :caption="'配達先やお届け日などの確認のためご連絡することがあります。'"
          :placementTextList="['組合員情報の電話番号と同じ', '新しい電話番号を登録する']"
          :value.sync="delivery.selectedTel"
          :errorMessages="selectedTelErrors()"
        />

        <v-sheet v-if="delivery.selectedTel === 1" class="rounded-lg memberid-black-100 pa-4 mt-8">
          <single-valid-text-field
            :inputTitle="phoneNumber.title"
            :requiredFlag="phoneNumber.required"
            :placeholder="phoneNumber.placeholder"
            :value.sync="delivery.phoneNumber"
            :errorMessages="validPhoneNumber()"
            :caption="'※ハイフン無し、半角数字'"
            v-on:blur="$v.delivery.phoneNumber.$touch()"
          />
        </v-sheet>
        <br />

        <radio-button
          :inputTitle="'お住まいについて'"
          :requiredFlag="true"
          :placementTextList="residenceTextList"
          :value.sync="delivery.selectedResidence"
          :errorMessages="selectedResidenceErrors()"
        />
        <br />

        <radio-button
          :inputTitle="'置き配時の置き場所'"
          :requiredFlag="true"
          :caption="'オートロックなど周囲の環境によってはご利用いただけない場合がございます。'"
          :placementTextList="placementTextList"
          :value.sync="delivery.selectedPlacement"
          :errorMessages="selectedPlacementErrors()"
        />
        <v-sheet v-if="delivery.selectedPlacement === 4" class="rounded-lg memberid-black-100 pa-4 mt-2">
          <v-text-field
            placeholder="ご希望の置き場所を入力ください"
            color="memberid-primary"
            background-color="memberid-white"
            v-model="delivery.placementOtherInput"
            :error="$v.delivery.placementOtherInput.$error"
            @blur="$v.delivery.placementOtherInput.$touch()"
            @update:error="validOtherInputErrors()"
            outlined
            hide-details
          ></v-text-field>
          <error-messages
            :isShow="validOtherInputErrors().length > 0"
            :messages="validOtherInputErrors()"
          ></error-messages>
        </v-sheet>
        <br />

        <radio-button
          :inputTitle="'お届け時の確認'"
          :requiredFlag="true"
          :caption="'ご連絡がある場合などはチャイム・インターホンを鳴らすこともございます。'"
          :placementTextList="confirmationOfDeliveryTextList"
          :value.sync="delivery.selectedConfirmationOfDelivery"
          :errorMessages="selectedConfirmationOfDeliveryErrors()"
        />
        <br />
        <div>
          <v-row>
            <v-col class="pt-4">
              <common-input-title :title="'カタログ・納品書等一式の配付申込'" :requiredFlag="true" :caption="'トドックでは環境保全の取組みとして、希望される組合員さまにカタログ一式をお配りしない取組みを行っております。\nカタログが不要の方は、トドックアプリ・サイトからご注文いただけます。'" />
              <p class="mb-0 memberid-black-500--text subtitle-2 text-indention">
                <a
                  style="text-decoration: underline; vertical-align:top;"
                  class="mb-0 memberid-secondary--text subtitle-2 text-indention font-weight-bold text-decoration-underline"
                  @click.stop
                  href="https://todok.sapporo.coop/static/catalog_stop/catalog_stop.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  >こちら</a
                >の内容を必ずご確認の上、ご入力ください。
              </p>
            </v-col>
          </v-row>
          <v-radio-group class="ml-3 mt-1" v-model="delivery.selectedDeliveryOfPaperCatalog" :error-messages="selectedDeliveryOfPaperCatalogErrors()" column hide-details>
            <v-radio color="memberid-primary" class="mt-3 mb-3" v-for="(text, i) in deliveryOfPaperCatalogTextList" :key="i" :value="i" style="display: flex;align-items: flex-start;">
              <template v-slot:label>
                <div v-bind:class="'memberid-black-600--text'">
                  {{ text }}
                  <p v-if="i === 0" class="mb-0 memberid-secondary--text subtitle-1 text-indention">
                    配付を希望しないを選択いただくと「いつでもポイント２倍」になります。
                  </p>
                  <p v-if="i === 0" class="mb-0 memberid-black-500--text subtitle-2 text-indention">
                    ※加入後3回のみ配付しますが、4回目以降は停止といたします。
                  </p>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-row v-if="selectedDeliveryOfPaperCatalogErrors()">
            <v-col>
              <error-messages :isShow="selectedDeliveryOfPaperCatalogErrors()" :messages="selectedDeliveryOfPaperCatalogErrors()"></error-messages>
            </v-col>
          </v-row>
        </div>
        <br />

        <div v-if="isOpenTodokTrialCampaign">
          <v-row>
            <v-col class="pt-4">
              <common-input-title :title="'【期間限定】トドックおためしキャンペーンお申込み'" :requiredFlag="true" :caption="'2024年7月26日～8月8日の期間限定！宅配トドックご加入検討中の皆さま、\n人気の5商品（1,923円相当）がもらえるキャンペーンを実施中です！！'" />
              <p class="mb-0 memberid-black-500--text subtitle-2 text-indention">
                <a
                  style="text-decoration: underline; vertical-align:top;"
                  class="mb-0 memberid-secondary--text subtitle-2 text-indention font-weight-bold text-decoration-underline"
                  @click.stop
                  href="https://todok.sapporo.coop/static/todok_otameshi/todok_otameshi_campaign_2024.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >こちら</a
                >の内容を必ずご確認の上、ご入力ください。
              </p>
            </v-col>
          </v-row>
          <v-radio-group class="ml-3 mt-1" v-model="delivery.selectedTodokTrialCampaign" :error-messages="selectedTodokTrialCampaignErrors()" column hide-details>
            <v-radio color="memberid-primary" class="mt-3 mb-3" v-for="(text, i) in todokTrialCampaignTextList" :key="i" :value="i" style="display: flex;align-items: flex-start;">
              <template v-slot:label>
                <div v-bind:class="'memberid-black-600--text'">
                  {{ text }}
                  <p v-if="i === 0" class="mb-0 memberid-secondary--text subtitle-1 text-indention">
                    訪問日時をお電話でおうかがいいたします。（業務携帯からおかけする場合があります）
                  </p>
                  <p v-if="i === 0" class="mb-0 memberid-black-500--text subtitle-2 text-indention">
                    訪問＆ご説明でたまごスープをプレゼント！<br />
                    おためし加入でのこり4商品をプレゼントいたします。
                  </p>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-row v-if="selectedTodokTrialCampaignErrors()">
            <v-col>
              <error-messages :isShow="selectedTodokTrialCampaignErrors()" :messages="selectedTodokTrialCampaignErrors()"></error-messages>
            </v-col>
          </v-row>
        </div>
        <br />

        <single-valid-text-field
          inputTitle="メールアドレス"
          placeholder="todok@sapporo.coop"
          caption="※半角英数字"
          :requiredFlag="true"
          :value.sync="delivery.email"
          :errorMessages="validEmail()"
          :disabledFlag="loginIsEmail"
          underText="配達やご注文などについての大事なお知らせをお送りします。"
          v-on:blur="$v.delivery.email.$touch()"
        />
        <br />

        <common-input-title
          title="今回のお申込みのきっかけは、トドックを利用しているお知り合いからのご紹介ですか？"
          :requiredFlag="true"
          caption="ご紹介の場合は、あなたにもご紹介者にも特典があります。"
        />
        <radio-button
          inputTitle=""
          :placementTextList="introducerTextList"
          :value.sync="delivery.selectedIntroducer"
          :errorMessages="selectedIntroducerErrors()"
        />
        <v-sheet v-if="delivery.selectedIntroducer === 1" class="rounded-lg memberid-black-100 pa-4 mt-2">
          <single-valid-text-field
            :inputTitle="'ご紹介者様のお名前'"
            :requiredFlag="true"
            placeholder="例）トドック 太郎"
            :value.sync="delivery.introducerName"
            :errorMessages="introducerNameErrors()"
            v-on:blur="$v.delivery.introducerName.$touch()"
          />
          <p class="mt-1 mb-6 todok-black-400--text subtitle-2">
            ご紹介者様の組合員番号か電話番号のどちらかをご入力ください。ご紹介者様を特定できない場合、連絡させていただくことがございます。
          </p>
          <single-valid-text-field
            :inputTitle="'ご紹介者様の組合員番号(8桁)'"
            caption="※半角数字"
            placeholder="12345678"
            :value.sync="delivery.introducerUnionNumber"
            :errorMessages="introducerUnionNumberErrors()"
            v-on:blur="$v.delivery.introducerUnionNumber.$touch()"
          />
          <single-valid-text-field
            :inputTitle="'ご紹介者様の電話番号'"
            caption="※ハイフン無し、半角数字"
            placeholder="09012345678"
            :value.sync="delivery.introducerPhoneNumber"
            :errorMessages="introducerPhoneNumberErrors()"
            v-on:blur="$v.delivery.introducerPhoneNumber.$touch()"
          />
        </v-sheet>
      </v-form>

      <rounded-button :buttonText="nextButtonText" :nextPage="nextPage" :isdisabled="!deliveryValid" />
    </v-container>
    <v-btn
      v-if="!lastViewIsConfirm"
      text
      @click="backToPreviousPage()"
      class="px-2 mt-6 memberid-primary--text subtitle-1"
    >
      ← 前へ戻る
    </v-btn>
  </div>
</template>
<script>
import SingleValidTextField from '@/components/SingleValidTextFieldComponent'
import RadioButton from '@/components/RadioButtonComponent'
import RoundedButton from '@/components/RoundedButtonComponent'
import SelectBox from '@/components/SelectComponent'
import ErrorMessages from '@/components/ErrorMessageListComponent'
import CommonInputTitle from '@/components/InputTitle'
import ZipCodeComponent from '@/components/ZipCodeComponent'

import DeliveryInformationMixin from '@/components/mixins/delivery-information-mixin'
import DeliveryInfo from '@/models/delivery-information'
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength, email } from 'vuelidate/lib/validators'
import store from '@/store'
import storage from '@/storage'
import { checkZenSpace, zenkaku, zenkakuWithSpace } from '@/lib/validations'
import Constants from '@/lib/constants'

export default {
  name: 'DeliveryInformation',
  mixins: [validationMixin, DeliveryInformationMixin],
  components: {
    SingleValidTextField,
    RadioButton,
    RoundedButton,
    SelectBox,
    ErrorMessages,
    ZipCodeComponent,
    CommonInputTitle,
  },
  validations() {
    let validObj = {
      delivery: {
        selectedAddress: { required },
        selectedTel: { required },
        selectedResidence: { required },
        selectedPlacement: { required },
        selectedConfirmationOfDelivery: { required },
        selectedDeliveryOfPaperCatalog: { required },
        // selectedTodokTrialCampaign: { required },
        selectedIntroducer: { required },
        email: { required, email },
      },
    }
    if (this.delivery.selectedAddress === 1) {
      validObj.delivery.zipCode = { required }
      validObj.delivery.address1 = { required }
      validObj.delivery.address2 = { required, checkZenSpace, zenkaku }
      validObj.delivery.address3 = { zenkakuWithSpace }
    }
    if (this.delivery.selectedTel === 1) {
      validObj.delivery.phoneNumber = { required, numeric, minLength: minLength(10), maxLength: maxLength(11) }
    }
    if (this.delivery.selectedPlacement === 4) {
      validObj.delivery.placementOtherInput = { required }
    }
    if (this.delivery.selectedIntroducer === 1) {
      validObj.delivery.introducerName = { required }
      validObj.delivery.introducerUnionNumber = {
        numeric,
        minLength: minLength(8),
        maxLength: maxLength(8),
        required(v) {
          return this.delivery.introducerPhoneNumber || required(v)
        },
      }
      validObj.delivery.introducerPhoneNumber = {
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(11),
        required(v) {
          return this.delivery.introducerUnionNumber || required(v)
        },
      }
    }
    if (Constants.FEATURE_FLAG_OPEN_TODOK_TRIAL_CAMPAIGN){
      validObj.delivery.selectedTodokTrialCampaign = { required }
    }

    return validObj
  },
  data() {
    return {
      loginIsEmail: false,
      deliveryValid: true,
      addressList: [],
      lastViewIsConfirm: false,
      nextButtonText: '次へ',
      residenceTextList: ['戸建て', '集合住宅でオートロック無し', '集合住宅でオートロック有り'],
      placementTextList: ['玄関フード', '物置', '車庫', '玄関ドア横', 'その他'],
      confirmationOfDeliveryTextList: ['チャイム・インターホンを鳴らす', 'チャイム・インターホンを鳴らさない'],
      deliveryOfPaperCatalogTextList: ['配付を希望しない', '配付を希望する'],
      todokTrialCampaignTextList:['希望する', '希望しない'],
      introducerTextList: ['いいえ', 'はい'],
      address1: {
        title: '市区町村',
        required: true,
        placeholder: '選択してください',
        messages: [
          'あてはまる住所が選択肢にない場合、お手数をおかけしますが、以下の相談窓口までお問い合わせください。',
          '【お問い合わせ】',
          '0120-307-919 (10:00〜18:00 ※日祝を除く)',
        ],
      },
      address2: {
        title: '以降の住所',
        required: true,
        placeholder: '２番地１−２',
      },
      address3: {
        title: '建物名・部屋番号',
        // required: false,
        placeholder: 'トドックアパート101',
      },
      phoneNumber: {
        title: '連絡先電話番号',
        required: true,
        placeholder: '09012345678',
      },
      delivery: { type: DeliveryInfo },
      placementOtherInputError: [],
      buttonDisabled: true,
      isOpenTodokTrialCampaign: Constants.FEATURE_FLAG_OPEN_TODOK_TRIAL_CAMPAIGN
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!from || from.name === null) {
      if (!storage.getMemberInfo()) {
        next({ name: 'MemberEntry' })
      }
    }
    next()
  },

  async mounted() {
    if (storage.lastViewIsConfirm()) {
      this.lastViewIsConfirm = true
      // 口座が登録されていれば確認画面。登録されていなければ口座登録に行くので初期表示
      if (storage.isAccountTransferStatus()) {
        this.nextButtonText = '内容確認へ戻る'
      }
    }
    this.delivery = new DeliveryInfo()
    const dd = await store.dispatch('getDeliveryInformation')
    if (dd) {
      this.delivery.zipCode = dd.zipCode
      this.delivery.address1 = dd.address1
      this.delivery.address2 = dd.address2
      this.delivery.address3 = dd.address3
      this.delivery.selectedAddress = dd.selectedAddress
      this.delivery.addressList = dd.addressList
      this.delivery.selectedTel = dd.selectedTel
      this.delivery.phoneNumber = dd.phoneNumber
      this.delivery.selectedResidence = dd.selectedResidence
      this.delivery.selectedPlacement = dd.selectedPlacement
      this.delivery.placementOtherInput = dd.placementOtherInput
      this.delivery.selectedConfirmationOfDelivery = dd.selectedConfirmationOfDelivery
      this.delivery.selectedDeliveryOfPaperCatalog = dd.selectedDeliveryOfPaperCatalog
      this.delivery.selectedTodokTrialCampaign = dd.selectedTodokTrialCampaign
      this.delivery.selectedIntroducer = dd.selectedIntroducer
      this.delivery.introducerName = dd.introducerName
      this.delivery.introducerUnionNumber = dd.introducerUnionNumber
      this.delivery.introducerPhoneNumber = dd.introducerPhoneNumber
      this.delivery.email = dd.email
    }

    // ログインに使っていたらログインIDを設定する
    const user = this.$auth.user
    const loginId = user.name
    if (loginId.includes('@')) {
      this.delivery.email = loginId
      this.loginIsEmail = true
    }
    // 何も設定されていなかったら組合員情報から設定してみる
    if (this.delivery.email.length === 0) {
      const member = await store.dispatch('getMember')
      this.delivery.email = member.email
    }
  },
  methods: {
    // 新しく住所リストを取得したら変数をリセットする
    clearAddress() {
      this.delivery.address1 = ''
    },

    async nextPage() {
      if (this.validate()) {
        return
      }
      this.setDateToStore()

      // 確認画面経由だったら
      if (storage.lastViewIsConfirm()) {
        // 口座を登録していたら確認画面
        if (storage.isAccountTransferStatus()) {
          store.dispatch('routerPushWithFromQuery', { name: 'MemberConfirm' })
          return
        }
      }
      store.dispatch('routerPushWithFromQuery', { name: 'CapitalEntry' })
    },

    backToPreviousPage() {
      this.$router.go(-1)
    },
    validate() {
      this.$v.$touch()
      return this.$v.$invalid
    },
    selectedAddressErrors() {
      const errors = []
      if (!this.$v.delivery.selectedAddress.$dirty) return errors
      !this.$v.delivery.selectedAddress.required && errors.push('宅配配達先住所を選択してください。')
      return errors
    },
    selectedTelErrors() {
      const errors = []
      if (!this.$v.delivery.selectedTel.$dirty) return errors
      !this.$v.delivery.selectedTel.required && errors.push('日中連絡の取れる電話番号を選択してください。')
      return errors
    },

    selectedResidenceErrors() {
      const errors = []
      if (!this.$v.delivery.selectedResidence.$dirty) return errors
      !this.$v.delivery.selectedResidence.required && errors.push('お住まいについてを選択してください。')
      return errors
    },

    selectedConfirmationOfDeliveryErrors() {
      const errors = []
      if (!this.$v.delivery.selectedConfirmationOfDelivery.$dirty) return errors
      !this.$v.delivery.selectedConfirmationOfDelivery.required && errors.push('お届け時の確認を選択してください。')
      return errors
    },

    selectedDeliveryOfPaperCatalogErrors() {
      const errors = []
      if (!this.$v.delivery.selectedDeliveryOfPaperCatalog.$dirty) return errors
      !this.$v.delivery.selectedDeliveryOfPaperCatalog.required && errors.push('カタログ・納品書等一式の配付申込を選択してください。')
      return errors
    },

    selectedTodokTrialCampaignErrors() {
      const errors = []
      if (!this.$v.delivery.selectedTodokTrialCampaign.$dirty) return errors
      !this.$v.delivery.selectedTodokTrialCampaign.required && errors.push('【期間限定】トドックおためしキャンペーンお申込みを選択してください。')
      return errors
    },

    validAddress1() {
      const errors = []
      if (!this.$v.delivery.address1.$dirty) return errors
      this.addressList.length === 0 &&
        errors.push(`住所情報が取得できませんでした。ご入力された郵便番号にお間違いがないかご確認ください。`)
      !this.$v.delivery.address1.required && errors.push(`${this.address1.title}をご入力ください`)
      return errors
    },
    validAddress2() {
      const errors = []
      if (!this.$v.delivery.address2.$dirty) return errors
      !this.$v.delivery.address2.required && errors.push(`${this.address2.title}をご入力ください`)
      !this.$v.delivery.address2.checkZenSpace && errors.push(`${this.address2.title}に全角スペースが含まれています`)
      !this.$v.delivery.address2.zenkaku && errors.push(`${this.address2.title}は全角でご入力ください`)
      return errors
    },
    validAddress3() {
      const errors = []
      !this.$v.delivery.address3.zenkakuWithSpace && errors.push(`${this.address3.title}に全角でご入力ください`)
      return errors
    },
    validPhoneNumber() {
      const errors = []
      if (!this.$v.delivery.phoneNumber.$dirty) return errors
      !this.$v.delivery.phoneNumber.required && errors.push(`${this.phoneNumber.title}をご入力ください`)
      !this.$v.delivery.phoneNumber.numeric && errors.push('半角数字でご入力ください。')
      !this.$v.delivery.phoneNumber.minLength && errors.push('文字数が不足しています。')
      !this.$v.delivery.phoneNumber.maxLength && errors.push('文字数が多いです。')
      return errors
    },
    validEmail() {
      const errors = []
      if (!this.$v.delivery.email.$dirty) return errors
      !this.$v.delivery.email.required && errors.push(`メールアドレスをご入力ください`)
      !this.$v.delivery.email.email && errors.push('メールアドレスの形式が不正です。')
      return errors
    },

    validOtherInputErrors() {
      const errors = []
      if (!this.$v.delivery.placementOtherInput.$dirty) return errors
      !this.$v.delivery.placementOtherInput.required && errors.push('ご希望の置き場所をご入力ください。')
      return errors
    },
    selectedPlacementErrors() {
      const errors = []
      if (!this.$v.delivery.selectedPlacement.$dirty) return errors
      !this.$v.delivery.selectedPlacement.required && errors.push('置き配時の置き場所を選択してください。')
      return errors
    },
    selectedIntroducerErrors() {
      const errors = []
      if (!this.$v.delivery.selectedIntroducer.$dirty) return errors
      !this.$v.delivery.selectedIntroducer.required && errors.push('選択されていません。')
      return errors
    },

    introducerNameErrors() {
      const errors = []
      if (!this.$v.delivery.introducerName.$dirty) return errors
      !this.$v.delivery.introducerName.required && errors.push('ご紹介者様のお客様をご入力ください。')
      return errors
    },

    introducerUnionNumberErrors() {
      const errors = []
      if (!this.$v.delivery.introducerUnionNumber.$dirty) return errors
      !this.$v.delivery.introducerUnionNumber.required &&
        errors.push('ご紹介者様の組合員番号もしくは電話番号をご入力ください。')
      !this.$v.delivery.introducerUnionNumber.numeric && errors.push('半角数字でご入力ください。')
      !this.$v.delivery.introducerUnionNumber.minLength && errors.push('文字数が不足しています。')
      !this.$v.delivery.introducerUnionNumber.maxLength && errors.push('文字数が多いです。')
      return errors
    },

    introducerPhoneNumberErrors() {
      const errors = []
      if (!this.$v.delivery.introducerPhoneNumber.$dirty) return errors
      !this.$v.delivery.introducerPhoneNumber.required &&
        errors.push('ご紹介者様の組合員番号もしくは電話番号をご入力ください。')
      !this.$v.delivery.introducerPhoneNumber.numeric && errors.push('半角数字でご入力ください。')
      !this.$v.delivery.introducerPhoneNumber.minLength && errors.push('文字数が不足しています。')
      !this.$v.delivery.introducerPhoneNumber.maxLength && errors.push('文字数が多いです。')
      return errors
    },
    setDateToStore() {
      const di = new DeliveryInfo()
      di.selectedAddress = this.delivery.selectedAddress
      di.zipCode = this.delivery.zipCode
      di.addressList = this.delivery.addressList
      di.address1 = this.delivery.address1
      di.address2 = this.delivery.address2
      di.address3 = this.delivery.address3
      di.selectedTel = this.delivery.selectedTel
      di.phoneNumber = this.delivery.phoneNumber
      di.selectedResidence = this.delivery.selectedResidence
      di.selectedPlacement = this.delivery.selectedPlacement
      di.placementOtherInput = this.delivery.placementOtherInput
      di.selectedConfirmationOfDelivery = this.delivery.selectedConfirmationOfDelivery
      di.selectedDeliveryOfPaperCatalog = this.delivery.selectedDeliveryOfPaperCatalog
      di.selectedTodokTrialCampaign = this.delivery.selectedTodokTrialCampaign
      di.selectedIntroducer = this.delivery.selectedIntroducer
      di.introducerName = this.delivery.introducerName
      di.introducerUnionNumber = this.delivery.introducerUnionNumber
      di.introducerPhoneNumber = this.delivery.introducerPhoneNumber
      di.email = this.delivery.email
      store.dispatch('setDeliveryInformation', { val: di })
    },
  },
  watch: {
    // delivery: {
    //   deep: true,
    //   handler: function() {
    //     console.log('delivery watch handler')
    //     const di = new DeliveryInfo()
    //     di.selectedAddress = this.delivery.selectedAddress
    //     di.zipCode = this.delivery.zipCode
    //     di.addressList = this.delivery.addressList
    //     di.address1 = this.delivery.address1
    //     di.address2 = this.delivery.address2
    //     di.address3 = this.delivery.address3
    //     di.selectedTel = this.delivery.selectedTel
    //     di.phoneNumber = this.delivery.phoneNumber
    //     di.selectedResidence = this.delivery.selectedResidence
    //     di.selectedPlacement = this.delivery.selectedPlacement
    //     di.placementOtherInput = this.delivery.placementOtherInput
    //     di.selectedConfirmationOfDelivery = this.delivery.selectedConfirmationOfDelivery
    //     di.selectedIntroducer = this.delivery.selectedIntroducer
    //     di.introducerName = this.delivery.introducerName
    //     di.introducerUnionNumber = this.delivery.introducerUnionNumber
    //     di.introducerPhoneNumber = this.delivery.introducerPhoneNumber
    //     di.email = this.delivery.email
    //     store.dispatch('setDeliveryInformation', { val: di })
    //   },
    // },
  },
  computed: {
    // placementOtherInputErrors() {
    //   const errors = []
    //   console.log('1', errors)
    //   if (!this.$v.placementOtherInput.$dirty) return errors
    //   console.log('2', errors)
    //   !this.$v.placementOtherInput.required && errors.push('ご希望の置き場所をご入力ください。')
    //   return errors
    // },
  },
}
</script>
